<div class="container-fluid px-0 bg-dark">
  <div class="row mx-0 px-0">
    <div class="col-12 mx-0 px-0">
      <a (click)="onMenuItemClick()" class="nav-item nav-link active" routerLink="/palyazat"><img src="../assets/szechenyi.png" height="64" alt=""></a>
    </div>
  </div>
</div>
<div class="container-fluid px-0 sticky-top">
  <div class="row mx-0 px-0">
    <div class="col-12 mx-0 px-0">
      <nav class="navbar navbar-expand-lg navbar-dark bg-dark">
        <a class="navbar-brand" routerLink="/">Halas Daru</a>
        <button #menuToggleButton class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarToggler" aria-controls="navbarTogglerDemo02" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarToggler">
          <ul class="navbar-nav mr-auto mt-2 mt-lg-0">
            <li class="nav-item active">
              <a (click)="onMenuItemClick()" class="nav-item nav-link active" routerLink="/bemutatkozas">Rólunk</a>
            </li>
            <li class="nav-item active">
              <a (click)="onMenuItemClick()" class="nav-item nav-link active" routerLink="/geppark">Géppark</a>
            </li>
            <li class="nav-item active">
              <a (click)="onMenuItemClick()" class="nav-item nav-link active" routerLink="/referenciak">Referenciák</a>
            </li>
            <li class="nav-item active">
              <a (click)="onMenuItemClick()" class="nav-item nav-link active" routerLink="/palyazat">Pályázat</a>
            </li>
            <li class="nav-item active">
              <a (click)="onMenuItemClick()" class="nav-item nav-link active" routerLink="/kapcsolat">Kapcsolat</a>
            </li>
          </ul>
        </div>
      </nav>
    </div>
  </div>
</div>

<div class="container-fluid px-0">
  <div class="row mx-0 px-0">
    <div class="col-12 mx-0 px-0">
      <router-outlet (activate)="onActivate()"></router-outlet>
    </div>
  </div>
</div>

