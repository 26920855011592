import { Component, OnInit } from '@angular/core';
import { faPhone, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import {Title} from "@angular/platform-browser";

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {

  faPhone = faPhone;
  faEnvelope = faEnvelope;

  constructor(private titleService: Title) { }

  ngOnInit(): void {
    this.titleService.setTitle('Kapcsolatfelvétel • Halas Daru • halasdaru.hu • daruzok.hu');
  }

}
