import {Component, OnInit} from '@angular/core';
import {faEnvelope, faPhone} from "@fortawesome/free-solid-svg-icons";
import {Title} from "@angular/platform-browser";

@Component({
  selector: 'app-homepage',
  templateUrl: './homepage.component.html',
  styleUrls: ['./homepage.component.scss']
})
export class HomepageComponent implements OnInit {

  faPhone = faPhone;
  faEnvelope = faEnvelope;

  constructor(private titleService: Title) { }

  ngOnInit(): void {
    this.titleService.setTitle('Halas Daru, Kiskunhalas • halasdaru.hu • daruzok.hu');
  }
}
