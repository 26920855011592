<div class="container-fluid p-0 open-image">
  <div class="overlay">
    <div class="container h-100">
      <div class="row h-100 d-flex align-items-end">
        <div class="col-12 text-center pb-3">
          <h1>Halas Daru</h1>

          <div class="col-12 horizontal-line my-3"></div>

          <div class="row justify-content-center">
            <div class="col-12 col-md-6 col-lg-4">
              <h2 class="p-0 m-0">www.halasdaru.hu</h2>
            </div>
            <div class="col-12 col-md-6 col-lg-4">
              <h2 class="p-0 m-0">www.daruzok.hu</h2>
            </div>
          </div>

          <div class="row justify-content-center">
            <div class="col-12 col-md-6 col-lg-4">
              <h3><fa-icon [icon]="faPhone"></fa-icon> +36 (30) 249 1286</h3>
            </div>
            <div class="col-12 col-md-6 col-lg-4">
              <h3><fa-icon [icon]="faEnvelope"></fa-icon> info@halasdaru.hu</h3>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="placeholder container-fluid p-0">
  <app-footer></app-footer>
</div>
