import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-palyazat',
  templateUrl: './palyazat.component.html',
  styleUrls: ['./palyazat.component.scss']
})
export class PalyazatComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
