<div class="container-fluid p-0 content-container">
  <div class="row m-0">

    <div class="col-12 col-md-6">
      <div class="row d-flex align-items-center h-100">
        <div class="col-12 p-5 text-center">
          <h1 class="content-h1 my-5">Géppark</h1>
          <h2 class="content-h2">Liebherr LTM 1030/2</h2>
          <p class="content-paragraph">
            35 tonnás autódaru, 30 méteres főgém, 15 méteres segédgém.<br>
          </p>
          <h2 class="content-h2">Liebherr LTM 1035</h2>
          <p class="content-paragraph">
            35 tonnás autódaru, 30 méteres főgém, 8,5 méteres segédgém.
          </p>
        </div>
      </div>
    </div>

    <div class="col-12 col-md-6 image-column"></div>

  </div>
</div>
<app-footer></app-footer>



