<div class="container-fluid p-0 content-container">
  <div class="row m-0">

    <div class="col-12 col-md-6">
      <div class="row d-flex align-items-center h-100">
          <div class="col-12 p-5 text-center">
            <h1 class="content-h1 my-5">Bemutatkozás</h1>
            <p class="content-paragraph">
              Cégünk 2009-ben alakult. Autódaru bérbeadással 2007-ben kezdünk el foglalkozni, Kiskunhalas és környéke területén. Gépeinket szakképzett kezelővel adjuk bérbe. Az emelési kapacitásunk 35 tonnáig, illetve 37 méteres magasságig terjed. Jelenleg kettő autódaruból álló gépparkkal állunk megrendelőink szolgálatára. Szakmai felkészültségünk, illetve gépeink műszaki állapota a garancia a paramétereken belüli emelési feladatok biztonságos és pontos elvégzésére. Egyaránt vállalunk rövid és tartós daruzási munkákat. Forduljon hozzánk bizalommal, ha Kiskunhalas és környéke  területén igényel daruzási szolgáltatást!
            </p>
          </div>
      </div>
    </div>

    <div class="col-12 col-md-6 image-column"></div>

  </div>
</div>
<app-footer></app-footer>
