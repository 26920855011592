<div class="container-fluid p-0 content-container">
  <div class="row m-0">

    <div class="col-12 col-md-6">
      <div class="row d-flex align-items-center h-100">
        <div class="col-12 p-5 text-center content-paragraph">
          <h1 class="content-h1 my-5">Referenciák</h1>
          <p>Kunacél Kft Csarnok Építse Pirtó</p>
          <p>Kunacél Kft Csarnok Építése Szíl</p>
          <p>Fliesen Bau Kft Tető Szigetelés Kiskunhalas</p>
          <p>Fliesen Bau Kft Csarnok Építése Kunfehértó</p>
          <p>Térváz Kft Panelezés Kunfehértó</p>
          <p>Térváz Kft Szalma Tároló Építése Harkakötöny</p>
          <p>Exterctrum Kft Cserepezés Kunfehértó</p>
          <p>Térváz Kft Csarnok Építése Ülés</p>
          <p>12 Raszter Kft Csarnok Építése Kiskunmajsa</p>
          <p>Kunacél Kft Sertés Telep Építése Kecel</p>
          <p>Kunacél Kft Sertés Telep Építése Dávod</p>
          <p>Plan and Bau Kft Iskola tető felújítása</p>
          <p>Top Ár Kft Sport Csarnok Tető felújítása Kiskunhalas</p>
          <p>Térváz Kft Gabona Tároló Építése Csikéria</p>
          <p>Hűtőház építése Páhi</p>
          <p>Balázs Balázs Kft Szervíz Felújítása</p>
          <p>Családi Ház építése Kiskunhalas</p>
          <p>Modinvest Kft Iskola Bővítése Kiskunhalas</p>
          <p>Modinvest Kft Iskola Bővítése Jánoshalma</p>
          <p>Panelezés Jánoshalma</p>
          <p>Kunacél Kft Csarnok Építése Kiskunhalas</p>
        </div>
      </div>
    </div>

    <div class="col-12 col-md-6 image-column"></div>

  </div>
</div>
<app-footer></app-footer>



