import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {HomepageComponent} from "./homepage/homepage.component";
import {AboutComponent} from "./about/about.component";
import {ContactComponent} from "./contact/contact.component";
import {ReferencesComponent} from "./references/references.component";
import {MachinesComponent} from "./machines/machines.component";
import {PalyazatComponent} from "./palyazat/palyazat.component";

const routes: Routes = [
  {path: '', component: HomepageComponent},
  {path: 'bemutatkozas', component: AboutComponent},
  {path: 'kapcsolat', component: ContactComponent},
  {path: 'referenciak', component: ReferencesComponent},
  {path: 'palyazat', component: PalyazatComponent},
  {path: 'geppark', component: MachinesComponent},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
