import {BrowserModule, Title} from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomepageComponent } from './homepage/homepage.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { AboutComponent } from './about/about.component';
import { ReferencesComponent } from './references/references.component';
import { ContactComponent } from './contact/contact.component';
import { FooterComponent } from './footer/footer.component';
import { MachinesComponent } from './machines/machines.component';
import {NgcCookieConsentConfig, NgcCookieConsentModule} from "ngx-cookieconsent";
import { PalyazatComponent } from './palyazat/palyazat.component';

const cookieConfig:NgcCookieConsentConfig = {
  "cookie": {
    "domain": "www.halasdaru.hu"
  },
  "position": "bottom-left",
  "revokable": true,
  "theme": "classic",
  "palette": {
    "popup": {
      "background": "#000000",
      "text": "#ffffff",
      "link": "#ffffff"
    },
    "button": {
      "background": "#f1d600",
      "text": "#000000",
      "border": "transparent"
    }
  },
  "showLink": false,
  "dismissOnScroll": true,
  "dismissOnTimeout": 3000,
  "type": "info",
  "content": {
    "message": "Ez az oldal nem használ sütiket (cookie).",
    "dismiss": "Értettem",
    "policy": "Cookie tájékoztatás"
  }
};

@NgModule({
  declarations: [
    AppComponent,
    HomepageComponent,
    AboutComponent,
    ReferencesComponent,
    ContactComponent,
    FooterComponent,
    MachinesComponent,
    PalyazatComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FontAwesomeModule,
    NgcCookieConsentModule.forRoot(cookieConfig)
  ],
  providers: [
    Title
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
