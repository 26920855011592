<div class="container-fluid p-0 content-container">
  <div class="row m-0">

    <div class="col-12 col-md-6">
      <div class="row d-flex align-items-center h-100">
        <div class="col-12 p-5 text-center">
          <h1 class="content-h1 my-5">Kapcsoltfelvétel</h1>
          <h3 class="content-h2 pb-5"><fa-icon [icon]="faPhone"></fa-icon> +36 (30) 249 1286</h3>
          <h3 class="content-h2"><fa-icon [icon]="faEnvelope"></fa-icon> info@halasdaru.hu</h3>
        </div>
      </div>
    </div>

    <div class="col-12 col-md-6 image-column"></div>

  </div>
</div>
<app-footer></app-footer>
