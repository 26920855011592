<div class="container-fluid footer">
  <div class="row">
    <div class="col-12 p-5 text-center">
      Copyright {{ thisYear }} • Minden jog fenntartva • Cocomara Kft., Kiskunhalas
    </div>
  </div>
  <div class="row">
    <div class="col-12 pb-5 text-center tremoloweb">
      Webfejlesztés: TremoloWeb Kft., Kiskunhalas • <a href="https://www.tremoloweb.hu" target="_blank">www.tremoloweb.hu</a>
    </div>
  </div>
</div>
